import $api from '../http';
import { AxiosResponse } from 'axios';

export default class JournalService {
	static async add(
		date: string,
		client: string,
		client_location: string,
		location: string,
		dive_method: string,
		description: string,
		lider: object,
		diver1: object,
		diver2: object,
		assistent: Object,
		client_representative: string,
		supervisor_verified: boolean,
		have_plan: boolean,
		diver1_info: Object,
		diver2_info: Object
	): Promise<AxiosResponse<any>> {
		return $api.post<any>('/journal/add', {
			date,
			client,
			client_location,
			location,
			dive_method,
			description,
			lider,
			diver1,
			diver2,
			assistent,
			client_representative,
			supervisor_verified,
			have_plan,
			diver1_info,
			diver2_info,
		});
	}
	static async getOne(id: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/${id}`);
	}
	static async getAll(page:number): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/all/${page}`);
	}
	static async getOneByNumber(
		number: string
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/number/${number}`);
	}
	static async getByYear(year: number, page:number): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/year/${year}/${page}`);
	}
	static async getByDate(date: string, page:number): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/date/${date}/${page}`);
	}
	static async getByPeriod(
		date1: string,
		date2: string,
		page:number
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/period/${date1}/${date2}/${page}`);
	}
	static async getByUser(id: string, page:number): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/user/${id}/${page}`);
	}
	static async getByClient(id: string, page:number): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/client/${id}/${page}`);
	}
	static async getByTable(id: number, page: number): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/journal/table/${id}/${page}`);
	}
	static async getCSVdata(): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/all`);
	}
	static async getCSVdataByYear(
		year: number
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/year/${year}`);
	}
	static async getCSVdataByNumber(
		number: string
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/number/${number}`);
	}
	static async getCSVdataByDate(
		date: string
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/date/${date}`);
	}
	static async getCSVdataByPeriod(
		date1: string,
		date2: string
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/period/${date1}/${date2}`);
	}
	static async getCSVdataByUser(
		id: string
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/user/${id}`);
	}
	static async getCSVdataByClient(
		id: string
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/client/${id}`);
	}
	static async getCSVdataByTable(
		id: number
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/csv/table/${id}`);
	}
}
